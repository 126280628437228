import React from "react";
import { Formik, ErrorMessage } from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import { TextField, MenuItem, Chip, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { eventStatus, fieldsName, eventType, fieldInfo } from "../constant";
import { DataLoader } from "../../../components/loader";
import {
  fileContext,
  initialLanguageFieldValue,
  initialLanguageArrayValue,
  languages,
  imageDimensions,
} from "../../../constants";
import { useUploadFiles } from "../../../utils/upload-file/hooks";
import { eventFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { useEventForm } from "./hook";
import ProgressChip from "../../../components/progress-chip";
import { setLanguage } from "../../../utils/auth";
import Croppier from "../../../components/image-croppier";
import { InputProps } from "../../../components/input-props";

const EventForm = (props) => {
  const {
    handleBannerFile,
    uploadError,
    fileBannerError,
    handleDeleteAFile,
    files,
    handlePopularBannerFile,
    handlePopularBannerWebFile,
    filePopularBannerError,
    filePopularBannerWebError,
    isFileUploadData,
    uploadProgress,
    handleEventVideoFile,
    videoUploadError,
    handleEventVideoBannerFile,
    handleCropping,
  } = useUploadFiles();

  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    formValues,
    handleFormUpdate,
    categories,
    subcategories,
    handleEventForm,
    isLoading,
    btn,
    handleAddClickRequirements,
    handleRemoveClickRequirements,
    handleFormUpdateRequirements,
    handleIsCourseChange,
    faqCategories,
    handleTextfieldChange,
    handleRemoveDescription,
    handleLanguge,
    handleCourseDescriptionField,
    handleLangugeData,
    setFormvalues,
    currentBanner,
    setCurrentBanner,
    currentPopularBannerWeb,
    setCurrentPopularBannerWeb,
    currentVideoBanner,
    setcurrentVideoBanner,
    currentPopularBannerApp,
    setCurrentPopularBannerApp,
    handleSubcategoryText,
  } = useEventForm(props);

  const {
    category,
    subcategory,
    title,
    description,
    short_description,
    status,
    fileBanner,
    is_popular,
    is_course,
    filePopularBanner,
    filePopularBannerWeb,
    requirements,
    type,
    videos,
    video_banners,
    language,
    faq_category,
    slug,
    attendees_limit,
    current_attendees_limit,
    exceeds_percentage,
    increment_percentage,
    broadcast_mins,
    after_popup_broadcast_mins,
    max_join_broadcast_attempts,
    meta_description,
    meta_keywords,
    meta_title,
  } = fieldsName;

  const {
    event_banner,
    event_video_banner,
    popular_event_web,
    popular_event_app,
  } = imageDimensions;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={eventFormSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        description: formValues?.description || initialLanguageFieldValue,
        short_description:
          formValues?.short_description || initialLanguageFieldValue,
        status: formValues?.status || "",
        language: formValues?.language || "en",
        category:
          (formValues?.category_id &&
            categories.find(
              (category) => category.id === formValues?.category_id
            )) ||
          (formValues?.category?.id &&
            categories.find(
              (category) => category.id === formValues?.category?.id
            )) ||
          null,
        subcategory:
          (formValues?.sub_category_id &&
            subcategories.find(
              (subcategory) => subcategory.id === formValues?.sub_category_id
            )) ||
          (formValues?.subcategory?.id &&
            subcategories.find(
              (subcategory) => subcategory.id === formValues?.subcategory?.id
            )) ||
          null,
        is_course: formValues?.is_course ? true : false,
        is_popular: formValues?.is_popular ? true : false,
        faq_category: formValues?.faq_category || [],
        filePopularBanner: files.filter(
          (file) => file.file_context === fileContext.POPULAR_BANNER
        ),
        filePopularBannerWeb: files.filter(
          (file) => file.file_context === fileContext.POPULAR_BANNER_WEB
        ),
        fileBanner: files.filter(
          (file) => file.file_context === fileContext.BANNER
        ),
        videos: files.filter(
          (file) =>
            file.file_context?.includes(fileContext.VIDEO) &&
            file.file_context.split(" ").length === 2
        ),
        video_banners: files.filter((file) =>
          file.file_context?.includes(fileContext.VIDEO_BANNER)
        ),
        requirements: formValues?.requirements || initialLanguageArrayValue,
        type: formValues.type || "",
        slug: formValues.slug || "",
        attendees_limit: formValues?.attendees_limit || "",
        current_attendees_limit: formValues?.current_attendees_limit || "",
        exceeds_percentage: formValues?.exceeds_percentage || "",
        increment_percentage: formValues?.increment_percentage || "",
        broadcast_mins: formValues?.broadcast_mins || "",
        after_popup_broadcast_mins:
          formValues?.after_popup_broadcast_mins || "",
        max_join_broadcast_attempts:
          formValues?.max_join_broadcast_attempts || "",
        meta_title: formValues?.meta_title || "",
        meta_description: formValues?.meta_description || "",
        meta_keywords: formValues?.meta_keywords || "",
      }}
      onSubmit={handleEventForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {!isLoading && (
              <div className="materila-form_inline-child sticky__element">
                <TextField
                  className="material-form__field"
                  name={language}
                  placeholder="Language"
                  label="Language"
                  select
                  value={rest.values.language}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    handleFormValueChange(rest, language);
                    handleFormUpdate(name, value);
                    setFieldValue(name, value);
                    if (!rest.values.title[value]) {
                      handleLanguge(title, value, "");
                    }
                    if (!rest.values.description[value]) {
                      handleLanguge(description, value, "");
                    }
                    if (!rest.values.short_description[value]) {
                      handleLanguge(short_description, value, "");
                    }
                    if (!rest.values.requirements[value]) {
                      handleLanguge(requirements, value, []);
                      setFieldValue(requirements, {
                        ...rest.values.requirements,
                        [value]: [],
                      });
                    }
                    if (btn === "Save") {
                      setLanguage(event.target.value);
                      handleLangugeData(rest, setFormvalues);
                    }
                  }}
                  onFocus={() => handleFocusField(language)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.language}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.id}
                      className="material-form__option"
                      value={language.value}
                    >
                      {language.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === language ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={language}
                  />
                )}
              </div>
            )}

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <Autocomplete
                  options={categories}
                  getOptionLabel={(category) =>
                    category.title ? category.title : ""
                  }
                  value={rest.values.category}
                  name={category}
                  onChange={(event, value) => {
                    setFieldValue(category, value);
                    setFieldValue(subcategory, "");
                    handleFormUpdate(category, value);
                  }}
                  onFocus={() => handleFocusField(category)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={category}
                      placeholder="Select Category"
                      label="Select Category"
                      variant="standard"
                    />
                  )}
                />
                {focusField === category ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={category}
                  />
                )}
              </div>
              {rest.values.category && (
                <div className="materila-form_inline-child">
                  <Autocomplete
                    options={subcategories.filter(
                      (subcategory) =>
                        subcategory?.category_id === rest?.values?.category?.id
                    )}
                    getOptionLabel={(subcategory) =>
                      subcategory.title ? subcategory.title : ""
                    }
                    value={rest.values.subcategory}
                    name={subcategory}
                    onChange={(event, value) => {
                      setFieldValue(subcategory, value);
                      handleFormUpdate(subcategory, value);
                    }}
                    onFocus={() => handleFocusField(subcategory)}
                    onBlur={() => handleBlurField()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="material-form__field"
                        name={subcategory}
                        placeholder="Select Subcategory"
                        label="Select Subcategory"
                        variant="standard"
                      />
                    )}
                    noOptionsText={handleSubcategoryText(rest)}
                  />

                  {focusField === subcategory ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={subcategory}
                    />
                  )}
                </div>
              )}
            </div>

            <div>
              <Autocomplete
                multiple
                options={faqCategories}
                getOptionLabel={(faq) => (faq.title ? faq.title : "")}
                value={rest.values.faq_category}
                onChange={(event, value) => {
                  setFieldValue(faq_category, value);
                  handleFormUpdate(faq_category, value);
                }}
                onFocus={() => handleFocusField(faq_category)}
                onBlur={() => {
                  handleBlurField();
                  //rest.handleBlur();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={faq_category}
                    placeholder="Select FAQ categories"
                    label="Select FAQ categories"
                    variant="standard"
                  />
                )}
              />

              {focusField === faq_category ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={faq_category}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={type}
                placeholder="Type"
                label="Type"
                select
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleFormUpdate(name, value);
                  handleFormValueChange(rest, type);
                }}
                onFocus={() => handleFocusField(type)}
                onBlur={() => handleBlurField()}
                value={rest.values.type}
              >
                {eventType.map((type) => (
                  <MenuItem
                    key={type.id}
                    className="material-form__option"
                    value={type.value}
                  >
                    {type.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === type ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={type}
                />
              )}
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, title);
                    handleLanguge(
                      "title",
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={description}
                  placeholder="Description"
                  label="Description"
                  type="text"
                  multiline
                  value={rest.values.description[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, description);
                    handleLanguge(
                      "description",
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(description)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === description ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={description}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={short_description}
                  placeholder="Short Description"
                  label="Short Description"
                  type="text"
                  multiline
                  value={rest.values.short_description[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, short_description);
                    handleFormValueChange(rest, title);
                    handleLanguge(
                      "short_description",
                      rest.values.language,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(short_description)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === short_description ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={short_description}
                  />
                )}
              </div>

              <div className="col-md-2 form__language">
                <Tooltip title="Language" placement="bottom">
                  <div>
                    {
                      languages.find(
                        (lang) => lang.value === rest.values.language
                      ).displayText
                    }
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={slug}
                placeholder="Slug"
                label="Slug"
                type="text"
                value={rest.values.slug}
                onChange={(event) => {
                  handleFormValueChange(rest, slug);
                  handleFormUpdate(slug, event.target.value);
                }}
                onFocus={() => handleFocusField(slug)}
                onBlur={() => handleBlurField()}
              />
              {focusField === slug ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={slug}
                />
              )}
            </div>
            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={attendees_limit}
                  placeholder="Optimum Batch Size"
                  label="Optimum Batch Size"
                  type="number"
                  value={rest.values.attendees_limit}
                  onChange={(event) => {
                    handleFormValueChange(rest, attendees_limit);
                    handleFormUpdate(attendees_limit, event.target.value);
                  }}
                  onFocus={() => handleFocusField(attendees_limit)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.attendees_limit)}
                />
                {focusField === attendees_limit ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={attendees_limit}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={current_attendees_limit}
                  placeholder="Displayed Seats Count"
                  label="Displayed Seats Count"
                  value={rest.values.current_attendees_limit}
                  onChange={(event) => {
                    handleFormValueChange(rest, current_attendees_limit);
                    handleFormUpdate(
                      current_attendees_limit,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(current_attendees_limit)}
                  onBlur={() => handleBlurField()}
                  I
                  InputProps={InputProps(fieldInfo.current_attendees_limit)}
                />
                {focusField === current_attendees_limit ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={current_attendees_limit}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={exceeds_percentage}
                  placeholder="Threshold % for changing Displayed Seats Count"
                  label="Threshold % for changing Displayed Seats Count (in % )"
                  type="number"
                  value={rest.values.exceeds_percentage}
                  onChange={(event) => {
                    handleFormValueChange(rest, exceeds_percentage);
                    handleFormUpdate(exceeds_percentage, event.target.value);
                  }}
                  onFocus={() => handleFocusField(exceeds_percentage)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.exceeds_percentage)}
                />

                {focusField === exceeds_percentage ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={exceeds_percentage}
                  />
                )}
              </div>
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={increment_percentage}
                  placeholder="% for updating new Displayed Seats Count"
                  label="% for updating new Displayed Seats Count (in % )"
                  type="number"
                  value={rest.values.increment_percentage}
                  onChange={(event) => {
                    handleFormValueChange(rest, increment_percentage);
                    handleFormUpdate(increment_percentage, event.target.value);
                  }}
                  onFocus={() => handleFocusField(increment_percentage)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.increment_percentage)}
                />
                {focusField === increment_percentage ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={increment_percentage}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={broadcast_mins}
                  placeholder="View Live Session"
                  label="View Live Session (in minutes)"
                  type="number"
                  value={rest.values.broadcast_mins}
                  onChange={(event) => {
                    handleFormValueChange(rest, broadcast_mins);
                    handleFormUpdate(broadcast_mins, event.target.value);
                  }}
                  onFocus={() => handleFocusField(broadcast_mins)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.broadcast_mins)}
                />
                {focusField === broadcast_mins ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={broadcast_mins}
                  />
                )}
              </div>

              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={after_popup_broadcast_mins}
                  placeholder="Rewatch Live Session"
                  label="Rewatch Live Session (in minutes)"
                  value={rest.values.after_popup_broadcast_mins}
                  onChange={(event) => {
                    handleFormValueChange(rest, after_popup_broadcast_mins);
                    handleFormUpdate(
                      after_popup_broadcast_mins,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(after_popup_broadcast_mins)}
                  onBlur={() => handleBlurField()}
                  I
                  InputProps={InputProps(fieldInfo.after_popup_broadcast_mins)}
                />
                {focusField === after_popup_broadcast_mins ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={after_popup_broadcast_mins}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={max_join_broadcast_attempts}
                  placeholder="Number of Times Second Pop-Up Shows"
                  label="Number of Times Second Pop-Up Shows"
                  type="number"
                  value={rest.values.max_join_broadcast_attempts}
                  onChange={(event) => {
                    handleFormValueChange(rest, max_join_broadcast_attempts);
                    handleFormUpdate(
                      max_join_broadcast_attempts,
                      event.target.value
                    );
                  }}
                  onFocus={() => handleFocusField(max_join_broadcast_attempts)}
                  onBlur={() => handleBlurField()}
                  InputProps={InputProps(fieldInfo.max_join_broadcast_attempts)}
                />
                {focusField === max_join_broadcast_attempts ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={max_join_broadcast_attempts}
                  />
                )}
              </div>
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(status, event.target.value);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {eventStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={meta_title}
                placeholder="Meta Title"
                label="Meta Title"
                type="text"
                value={rest.values.meta_title}
                onChange={(event) => {
                  handleFormValueChange(rest, meta_title);
                  handleFormUpdate(meta_title, event.target.value);
                }}
                onFocus={() => handleFocusField(meta_title)}
                onBlur={() => handleBlurField()}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_title}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={meta_description}
                placeholder="Meta Description"
                label="Meta Description"
                type="text"
                multiline
                value={rest.values.meta_description}
                onChange={(event) => {
                  handleFormValueChange(rest, meta_description);
                  handleFormUpdate(meta_description, event.target.value);
                }}
                onFocus={() => handleFocusField(meta_description)}
                onBlur={() => handleBlurField()}
              />
              {focusField === meta_description ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_description}
                />
              )}
            </div>

            <div>
              <TextField
                className="material-form__field"
                name={meta_keywords}
                placeholder="Ex. Trending,Popular,etc."
                label="Meta Keywords"
                type="text"
                value={rest.values.meta_keywords}
                onChange={(event) => {
                  handleFormValueChange(rest, meta_keywords);
                  handleFormUpdate(meta_keywords, event.target.value);
                }}
                onFocus={() => handleFocusField(meta_keywords)}
                onBlur={() => handleBlurField()}
              />
              {focusField === meta_keywords ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={meta_keywords}
                />
              )}
            </div>

            <div className="form__inline-items">
              <div className="col-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rest.values.is_course}
                      onChange={() => {
                        handleFormValueChange(rest, is_course);
                        handleFormUpdate(is_course, !rest.values.is_course);
                        handleIsCourseChange(
                          rest,
                          setFieldValue,
                          !rest.values.is_course
                        );
                      }}
                      name={is_course}
                      color="default"
                    />
                  }
                  label="Course"
                />
              </div>
            </div>
            <div className="form__inline-items">
              <div className="col-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rest.values.is_popular}
                      onChange={() => {
                        handleFormValueChange(rest, is_popular);
                        handleFormUpdate(is_popular, !rest.values.is_popular);
                      }}
                      name={is_popular}
                      color="default"
                    />
                  }
                  label="Popular"
                />
              </div>
              {rest.values.is_popular && (
                <div className="form__form-group-field col-9">
                  <div className="form__form-group-input-wrap">
                    <div className="form__form-group-file">
                      <label htmlFor="filePopularBanner">
                        Choose Popular Banner - App
                      </label>
                      <Tooltip
                        title="Preferred size is 324px * 378px"
                        placement="bottom"
                      >
                        <i className="fa fa-info-circle icon-file-info mt-1"></i>
                      </Tooltip>
                      <br />
                      {files
                        .filter(
                          (file) =>
                            file.file_context === fileContext.POPULAR_BANNER
                        )
                        .map((x) => {
                          return (
                            <Chip
                              className="selected-file"
                              key={x.uuid_file_name}
                              label={x.original_file_name}
                              onDelete={() => handleDeleteAFile(x)}
                              variant="outlined"
                            />
                          );
                        })}

                      {isFileUploadData
                        .filter(
                          (file) =>
                            file.fileContext === fileContext.POPULAR_BANNER
                        )
                        .map((x, i) => {
                          return (
                            <div key={i}>
                              {x.isUpload && (
                                <ProgressChip
                                  fileName={x.fileName}
                                  uploadProgress={uploadProgress}
                                />
                              )}
                            </div>
                          );
                        })}

                      <input
                        accept="image/*"
                        onBlur={rest.handleBlur}
                        type="file"
                        name={filePopularBanner}
                        id="filePopularBanner"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onChange={(event) => {
                          handleCropping(
                            event.currentTarget.files,
                            setFieldValue,
                            filePopularBanner,
                            fileContext.POPULAR_BANNER,
                            handlePopularBannerFile,
                            popular_event_app,
                            setCurrentPopularBannerApp
                          );
                        }}
                      />
                    </div>
                    {rest.errors.filePopularBanner ? (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form-error-image">{msg}</div>
                        )}
                        name={filePopularBanner}
                      />
                    ) : (
                      <div className="material-form-error-image">
                        {filePopularBannerError || uploadError}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {currentPopularBannerApp?.length > 0 &&
              currentPopularBannerApp?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={filePopularBanner}
                    context={fileContext.POPULAR_BANNER}
                    dimensions={{
                      width: 324,
                      height: 378,
                    }}
                    handleUploadFile={handlePopularBannerFile}
                    setCurrentBanner={setCurrentPopularBannerApp}
                    index={i}
                  />
                </div>
              ))}

            <br />
            <div className="form__inline-items">
              <div className="col-3"></div>
              {rest.values.is_popular && (
                <>
                  <div className="form__form-group-field col-9">
                    <div className="form__form-group-input-wrap">
                      <div className="form__form-group-file">
                        <label htmlFor="filePopularBannerWeb">
                          Choose Popular Banner - Web
                        </label>
                        <Tooltip
                          title="Preferred size is 324px * 378px"
                          placement="bottom"
                        >
                          <i className="fa fa-info-circle icon-file-info mt-1"></i>
                        </Tooltip>
                        <br />
                        {files
                          .filter(
                            (file) =>
                              file.file_context ===
                              fileContext.POPULAR_BANNER_WEB
                          )
                          .map((x) => {
                            return (
                              <Chip
                                className="selected-file"
                                key={x.uuid_file_name}
                                label={x.original_file_name}
                                onDelete={() => handleDeleteAFile(x)}
                                variant="outlined"
                              />
                            );
                          })}
                        {isFileUploadData
                          .filter(
                            (file) =>
                              file.fileContext ===
                              fileContext.POPULAR_BANNER_WEB
                          )
                          .map((x, i) => {
                            return (
                              <div key={i}>
                                {x.isUpload && (
                                  <ProgressChip
                                    fileName={x.fileName}
                                    uploadProgress={uploadProgress}
                                  />
                                )}
                              </div>
                            );
                          })}
                        <input
                          accept="image/*"
                          onBlur={rest.handleBlur}
                          type="file"
                          name={filePopularBannerWeb}
                          id="filePopularBannerWeb"
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          onChange={(event) => {
                            handleCropping(
                              event.currentTarget.files,
                              setFieldValue,
                              filePopularBannerWeb,
                              fileContext.POPULAR_BANNER_WEB,
                              handlePopularBannerWebFile,
                              popular_event_web,
                              setCurrentPopularBannerWeb
                            );
                          }}
                        />
                      </div>
                      {rest.errors.filePopularBannerWeb ? (
                        <ErrorMessage
                          render={(msg) => (
                            <div className="material-form-error-image">
                              {msg}
                            </div>
                          )}
                          name={filePopularBannerWeb}
                        />
                      ) : (
                        <div className="material-form-error-image">
                          {filePopularBannerWebError || uploadError}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {currentPopularBannerWeb?.length > 0 &&
              currentPopularBannerWeb?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={filePopularBannerWeb}
                    context={fileContext.POPULAR_BANNER_WEB}
                    dimensions={popular_event_web}
                    handleUploadFile={handlePopularBannerWebFile}
                    setCurrentBanner={setCurrentPopularBannerWeb}
                    index={i}
                  />
                </div>
              ))}

            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="fileBanner">Choose Banners</label>
                  <Tooltip
                    title="Preferred size is 719px * 412px"
                    placement="bottom"
                  >
                    <i className="fa fa-info-circle icon-file-info mt-1"></i>
                  </Tooltip>
                  <br />
                  {files
                    .filter((file) => file.file_context === fileContext.BANNER)
                    .map((x) => {
                      return (
                        <Chip
                          className="selected-file"
                          key={x.uuid_file_name}
                          label={x.original_file_name}
                          onDelete={() => handleDeleteAFile(x)}
                          variant="outlined"
                        />
                      );
                    })}

                  {isFileUploadData
                    .filter((file) => file.fileContext === fileContext.BANNER)
                    .map((x, i) => {
                      return (
                        <div key={i}>
                          {x.isUpload && (
                            <ProgressChip
                              fileName={x.fileName}
                              uploadProgress={uploadProgress}
                            />
                          )}
                        </div>
                      );
                    })}
                  <input
                    accept="image/png, image/jpeg, image/bmp, image/webp"
                    multiple
                    type="file"
                    name={fileBanner}
                    id="fileBanner"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleCropping(
                        event.currentTarget.files,
                        setFieldValue,
                        fileBanner,
                        fileContext.BANNER,
                        handleBannerFile,
                        event_banner,
                        setCurrentBanner
                      );
                    }}
                  />
                </div>
                {rest.errors.fileBanner ? (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form-error-image">{msg}</div>
                    )}
                    name={fileBanner}
                  />
                ) : (
                  <div className="material-form-error-image">
                    {fileBannerError || uploadError}
                  </div>
                )}
              </div>
            </div>

            {currentBanner?.length > 0 &&
              currentBanner?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={fileBanner}
                    context={fileContext.BANNER}
                    dimensions={event_banner}
                    handleUploadFile={handleBannerFile}
                    setCurrentBanner={setCurrentBanner}
                    index={i}
                  />
                </div>
              ))}

            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="videos">Choose Videos</label>
                  {files
                    .filter(
                      (file) =>
                        file.file_context?.includes(fileContext.VIDEO) &&
                        file.file_context.split(" ").length === 2
                    )
                    .map((x) => {
                      return (
                        <Chip
                          className="selected-file"
                          key={x.uuid_file_name}
                          label={x.original_file_name}
                          onDelete={() => {
                            let banner_context_index =
                              x?.file_context.split(" ")[1];
                            const file = files.filter(
                              (file) =>
                                file.file_context ===
                                `${fileContext.VIDEO_BANNER} ${banner_context_index}`
                            );
                            handleDeleteAFile(x);
                            if (file.length) handleDeleteAFile(file[0]);
                          }}
                          variant="outlined"
                        />
                      );
                    })}

                  {isFileUploadData
                    .filter(
                      (file) =>
                        file.fileContext?.includes(fileContext.VIDEO) &&
                        file.fileContext.split(" ").length === 2
                    )
                    .map((x, i) => {
                      return (
                        <div key={i}>
                          {x.isUpload && (
                            <ProgressChip
                              fileName={x.fileName}
                              uploadProgress={uploadProgress}
                            />
                          )}
                        </div>
                      );
                    })}
                  <input
                    accept="video/*"
                    type="file"
                    name={videos}
                    id="videos"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleEventVideoFile(
                        event.currentTarget.files,
                        `${fileContext.VIDEO} ${
                          files.filter(
                            (file) =>
                              file.file_context?.includes(fileContext.VIDEO) &&
                              file.file_context.split(" ").length === 2
                          ).length + 1
                        }`,
                        setFieldValue
                      );
                      setFieldValue(
                        videos,
                        files.filter(
                          (file) =>
                            file.file_context ===
                            `${fileContext.VIDEO} ${
                              files.filter(
                                (file) =>
                                  file.file_context?.includes(
                                    fileContext.VIDEO
                                  ) && file.file_context.split(" ").length === 2
                              ).length + 1
                            }`
                        )
                      );
                    }}
                  />
                </div>

                {rest.errors.videos && (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form-error-image">{msg}</div>
                    )}
                    name={videos}
                  />
                )}
                {(videoUploadError || uploadError) && (
                  <div className="material-form-error-image">
                    {videoUploadError || uploadError}
                  </div>
                )}
              </div>
            </div>

            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <label htmlFor="video_banners">Choose Video Banners</label>
                  <Tooltip
                    title="Preferred size is 719px * 412px"
                    placement="bottom"
                  >
                    <i className="fa fa-info-circle icon-file-info mt-1"></i>
                  </Tooltip>
                  <br />
                  {files
                    .filter((file) =>
                      file.file_context?.includes(fileContext.VIDEO_BANNER)
                    )
                    .map((x) => {
                      return (
                        <Chip
                          className="selected-file"
                          key={x.uuid_file_name}
                          label={x.original_file_name}
                          onDelete={() => handleDeleteAFile(x)}
                          variant="outlined"
                        />
                      );
                    })}

                  {isFileUploadData
                    .filter((file) =>
                      file.fileContext?.includes(fileContext.VIDEO_BANNER)
                    )
                    .map((x, i) => {
                      return (
                        <div key={i}>
                          {x.isUpload && (
                            <ProgressChip
                              fileName={x.fileName}
                              uploadProgress={uploadProgress}
                            />
                          )}
                        </div>
                      );
                    })}
                  <input
                    accept="image/*"
                    type="file"
                    name={video_banners}
                    id="video_banners"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleCropping(
                        event.currentTarget.files,
                        setFieldValue,
                        video_banners,
                        fileContext.VIDEO_BANNER,
                        handleEventVideoBannerFile,
                        event_video_banner,
                        setcurrentVideoBanner
                      );
                      // handleEventVideoBannerFile(
                      //   event.currentTarget.files,
                      //   `${fileContext.VIDEO_BANNER} ${
                      //     files.filter((file) =>
                      //       file.file_context?.includes(
                      //         fileContext.VIDEO_BANNER
                      //       )
                      //     ).length + 1
                      //   }`,
                      //   setFieldValue
                      // );
                      // setFieldValue(
                      //   videos,
                      //   files.filter(
                      //     (file) =>
                      //       file.file_context ===
                      //       `${fileContext.VIDEO} ${
                      //         files.filter((file) =>
                      //           file.file_context?.includes(
                      //             fileContext.VIDEO_BANNER
                      //           )
                      //         ).length + 1
                      //       }`
                      //   )
                      // );
                    }}
                  />
                </div>

                {rest.errors.video_banners && (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form-error-image">{msg}</div>
                    )}
                    name={video_banners}
                  />
                )}
              </div>
            </div>

            {currentVideoBanner?.length > 0 &&
              currentVideoBanner?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={video_banners}
                    context={fileContext.VIDEO_BANNER}
                    dimensions={{
                      width: 719,
                      height: 412,
                    }}
                    handleUploadFile={handleEventVideoBannerFile}
                    setCurrentBanner={setcurrentVideoBanner}
                    index={i}
                  />
                </div>
              ))}

            <div className="section__container">
              <div className="form__inline-items">
                <div className="col-11 event_left_pad0">
                  <p className="bold-text">Requirements</p>
                </div>

                <div className="col-1 event_right_pad0">
                  <Tooltip title="Add" placement="bottom" className="">
                    <i
                      className={`fa fa-plus icon-btn-action text-success`}
                      onClick={() =>
                        handleAddClickRequirements(
                          rest,
                          setFieldValue,
                          rest.values.language
                        )
                      }
                    ></i>
                  </Tooltip>
                </div>
              </div>

              {rest?.values?.requirements[rest.values.language].map((x, i) => {
                return (
                  <div key={x + i} className="event_requirements">
                    <div className="form__inline-items align-items-center">
                      <div className="col-9 event_left_pad0">
                        <TextField
                          className="material-form__field mr-1"
                          name={`${requirements}.${i}.${title}`}
                          label="Title"
                          placeholder="Title"
                          value={x?.title}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleFormUpdateRequirements(
                              "title",
                              value,
                              i,
                              rest
                            );
                          }}
                          onFocus={(e) => {
                            const { name } = e.target;
                            handleFocusField(name);
                          }}
                          onBlur={() => handleBlurField()}
                        />
                        {focusField ===
                        `${requirements}.${i}.${title}` ? null : (
                          <ErrorMessage
                            render={(msg) => (
                              <div className="material-form__error">{msg}</div>
                            )}
                            name={`${requirements}.${i}.${title}`}
                          />
                        )}
                      </div>

                      <div className="col-md-2 form__language">
                        <Tooltip title="Language" placement="bottom">
                          <div>
                            {
                              languages.find(
                                (lang) => lang.value === rest.values.language
                              ).displayText
                            }
                          </div>
                        </Tooltip>
                      </div>
                      <div className="col-1 event_right_pad0">
                        <Tooltip
                          title="Delete"
                          placement="bottom"
                          className="event_top_pad10"
                        >
                          <i
                            className={`fa fa-trash icon-btn-action text-danger`}
                            onClick={() =>
                              handleRemoveClickRequirements(
                                rest,
                                setFieldValue,
                                i
                              )
                            }
                          ></i>
                        </Tooltip>
                      </div>
                    </div>
                    {rest?.values?.is_course ? (
                      <div className="form__inline-items">
                        <div className="col-9 schedule__event_left_pad0">
                          <div className="text-field-input">
                            <TextField
                              className="material-form__field"
                              name={`Description`}
                              label={`Description`}
                              placeholder={`Description`}
                              type="text"
                              onChange={(event) =>
                                handleTextfieldChange(
                                  event,
                                  x?.description,
                                  setFieldValue,
                                  rest,
                                  `${requirements}.${i}.${description}`,
                                  i
                                )
                              }
                              onKeyDown={(event) =>
                                handleTextfieldChange(
                                  event,
                                  x?.description,
                                  setFieldValue,
                                  `${requirements}.${i}.${description}`,
                                  rest,
                                  i
                                )
                              }
                              onFocus={(e) => {
                                const { name } = e.target;
                                handleFocusField(name);
                              }}
                              onBlur={() => handleBlurField()}
                            />
                          </div>
                          {focusField ===
                          `${requirements}.${i}.${description}` ? null : (
                            <ErrorMessage
                              render={(msg) => (
                                <div className="material-form__error">
                                  {msg}
                                </div>
                              )}
                              name={`${requirements}.${i}.${description}`}
                            />
                          )}

                          {x?.description?.map((desc, j) => {
                            return (
                              <div key={i + j}>
                                <div className="form__inline-items">
                                  <span className="mt-1 mr-1">{`${
                                    j + 1
                                  }.`}</span>
                                  <div className="col-9 event_left_pad0">
                                    <TextField
                                      className="material-form__field"
                                      type="text"
                                      placeholder="Enter description here"
                                      name={`${requirements}.${i}.${description}.${j}`}
                                      value={desc}
                                      onChange={(e) => {
                                        const { name, value } = e.target;
                                        setFieldValue(name, value);
                                        handleCourseDescriptionField(
                                          rest,
                                          value,
                                          i,
                                          j,
                                          setFieldValue
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="col-2 event_right_pad0">
                                    <Tooltip
                                      title="Delete Description"
                                      placement="bottom"
                                      className="event_top_pad10"
                                    >
                                      <i
                                        className={`fa fa-trash icon-btn-action text-danger`}
                                        onClick={() =>
                                          handleRemoveDescription(
                                            rest,
                                            x?.description,
                                            setFieldValue,
                                            desc,
                                            `${requirements}.${i}.${description}`,
                                            i
                                          )
                                        }
                                      ></i>
                                    </Tooltip>
                                  </div>
                                </div>
                                {focusField ===
                                `${requirements}.${i}.${description}.${j}` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${requirements}.${i}.${description}.${j}`}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-2 schedule__event_right_pad0"></div>
                      </div>
                    ) : (
                      // <div className="form__inline-items">
                      //   <div className="col-11 event_left_pad0">
                      //     <TextfieldChips
                      //       initialChips={x?.description || []}
                      //       name="Description"
                      //       onDataChange={(data) => {
                      //         setFieldValue(
                      //           `${requirements}.${i}.${description}`,
                      //           data
                      //         );
                      //         handleFormUpdateRequirements(
                      //           "description",
                      //           data,
                      //           i,
                      //           rest
                      //         );
                      //       }}
                      //     />
                      //     {focusField ===
                      //     `${requirements}.${i}.${description}` ? null : (
                      //       <ErrorMessage
                      //         render={(msg) => (
                      //           <div className="material-form__error">
                      //             {msg}
                      //           </div>
                      //         )}
                      //         name={`${requirements}.${i}.${description}`}
                      //       />
                      //     )}
                      //   </div>
                      // </div>
                      <div className="form__inline-items align-items-center">
                        <div className="col-9 event_left_pad0">
                          <TextField
                            className="material-form__field mr-1"
                            multiline
                            name={`${requirements}.${i}.${description}`}
                            label="Description"
                            placeholder="Description"
                            value={x?.description}
                            onChange={(e) => {
                              const { value } = e.target;
                              handleFormUpdateRequirements(
                                "description",
                                value,
                                i,
                                rest
                              );
                            }}
                            onFocus={(e) => {
                              const { name } = e.target;
                              handleFocusField(name);
                            }}
                            onBlur={() => handleBlurField()}
                          />
                          {focusField ===
                          `${requirements}.${i}.${description}` ? null : (
                            <ErrorMessage
                              render={(msg) => (
                                <div className="material-form__error">
                                  {msg}
                                </div>
                              )}
                              name={`${requirements}.${i}.${description}`}
                            />
                          )}
                        </div>
                        <div className="col-md-2 form__language">
                          <Tooltip title="Language" placement="bottom">
                            <div>
                              {
                                languages.find(
                                  (lang) => lang.value === rest.values.language
                                ).displayText
                              }
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <button
              disabled={isFileUploadData.length}
              type="submit"
              className="btn btn-success btn-right btn-sm"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default EventForm;
