export default {
  requestOtp: "/request_otp",
  userLogin: "/users/login",
  getLoggedInUser: (id = "") => `/users/${id}`,

  getUserEventSchedules: (id = "") => `/users/${id}/event_schedules`,
  getUserInterests: (id = "") => `/users/${id}/interests`,
  getUserTransactionHistories: (id = "") =>
    `/users/${id}/transaction_histories`,
  getUserPaymentHistories: (id = "") => `/users/${id}/payment_histories`,
  getUserReviews: (id = "") => `/users/${id}/reviews`,
  downloadInvoice: (id = "") => `/payment_histories/${id}/invoice`,
  addBonusSession: (id = "") =>
    `/users/${id}/transaction_histories/add_bonus_session`,
  getSessionsCount: (id = "") => `/users/${id}/sessions/stats`,
  dowloadBookingHistory: (id = "") => `/users/${id}/event_schedules/export`,
  dowloadPaymentHistory: (id = "") => `/users/${id}/payment_histories/export`,
  dowloadCreditUsageHistory: (id = "") =>
    `/users/${id}/transaction_histories/export`,
  getUserWaitlists: (id = "") => `/users/${id}/waitlists`,
  getReferals: (id = "") => `/users/${id}/referrals`,
  cancelScheduledEvent: (eventScheduleId = "", userId = "") =>
    `/users/${userId}/event_schedules/${eventScheduleId}/cancel`,
  getRefundDetails: (userId = "", eventScheduleId = "") =>
    `/users/${userId}/event_schedules/${eventScheduleId}/refund_policy`,
  bookeUserEvent: (userId = "", eventScheduleId) =>
    `/users/${userId}/event_schedules/${eventScheduleId}/book`,
  getEventTime: (id = "") => `/events/${id}/schedules`,
  getActivePlans: `/plans/active`,
  generatePaymentLink: (userId = "", planVariantId = "") =>
    `/users/${userId}/plan_variants/${planVariantId}/payment_link`,
  getStats: `/payment_histories/stats`,
  getPaymenthistories: `/payment_histories`,
  downloadPaymentHistories: `/payment_histories/export`,
  getUserActivePlans: (id = "") => `/users/${id}/plans/active`,

  getUsers: "/users",
  createUsers: "/users",
  updateUsers: (id = "") => `/users/${id}`,
  getUser: (id = "") => `/users/${id}`,
  exportUsers: "/users/export",

  //courses
  getCourses: "/courses",
  createCourse: "/courses",
  updateCourse: (id = "") => `/courses/${id}`,
  getCourse: (id = "") => `/courses/${id}`,
  cancelCourse: (id = "") => `/courses/${id}/cancel`,
  getCourseNotes: (id = "") => `/courses/${id}/notes`,
  getCourseReviews: (id = "") => `/courses/${id}/reviews`,
  getUserCourses: (id = "") => `/users/${id}/courses`,
  exportCourses: "/courses/export",
  canceUserCourse: (userId = "", courseId = "") =>
    `/users/${userId}/courses/${courseId}/cancel`,

  getCategories: "/categories",
  createCategories: "/categories",
  updateCategories: (id = "") => `/categories/${id}`,
  getCategory: (id = "") => `/categories/${id}`,
  deleteCategories: (id = "") => `/categories/${id}`,

  getSubategories: "/sub_categories",
  createSubcategory: "/sub_categories",
  updateSubcategory: (id = "") => `/sub_categories/${id}`,
  getSubcategory: (id = "") => `/sub_categories/${id}`,
  deleteSubcategories: (id = "") => `/sub_categories/${id}`,

  getBlogs: "/blogs",
  createBlog: "/blogs",
  getBlog: (id = "") => `/blogs/${id}/edit`,
  deleteBlog: (id = "") => `/blogs/${id}`,
  updateBlog: (id = "") => `/blogs/${id}`,
  exportBlogs: "/blogs/export",
  importBlogs: "/blogs/import",
  exportSampleBlogs: "/blogs/export/sample_file",

  getSeniorTodayBlogs: "/blogs/seniorstoday",
  createSeniorTodayBlog: "/blogs/seniorstoday",
  getSeniorTodayBlog: (id = "") => `/blogs/seniorstoday/${id}`,
  deleteSeniorTodayBlog: (id = "") => `/blogs/seniorstoday/${id}`,
  updateSeniorTodayBlog: (id = "") => `/blogs/seniorstoday/${id}`,

  getMagazines: "/magazines",
  createMagazines: "/magazines",
  getMagazine: (id = "") => `/magazines/${id}`,
  deleteMagazine: (id = "") => `/magazines/${id}`,
  updateMagazine: (id = "") => `/magazines/${id}`,

  getBanners: "/banner_groups",
  addBanner: `/banner_groups`,
  updateBanner: (id = "") => `banner_groups/${id}`,
  getSingleBanner: `/banners`,
  deleteBanner: (id = "") => `banner_groups/${id}`,

  getLabels: "/labels",
  createLabel: "/labels",
  getLabel: (id = "") => `/labels/${id}`,
  deleteLabel: (id = "") => `/labels/${id}`,
  updateLabel: (id = "") => `/labels/${id}`,

  getVideos: "/videos",
  addVideo: "/videos",
  deleteVideo: (id = "") => `/videos/${id}`,
  updateVideo: (id = "") => `/videos/${id}`,
  getSingleVideo: (id = "") => `/videos/${id}`,

  //partners
  addPartners: "/partners",
  getPartners: "/partners",
  editPartners: (id = "") => `/partners/${id}`,
  getSinglePartner: (id = "") => `/partners/${id}`,

  //partner-offers
  addPartnersOffers: "/partners_offers",
  editPartnersOffers: (id = "") => `/partners_offers/${id}`,
  getPartnersOffers: `/partners_offers`,
  getSingleOffer: (id = "") => `/partners_offers/${id}`,
  uploadOfferCode: (id = "") => `/partners_offers/${id}/codes`,
  getUserPartnerOffers: (id = "") => `/users/${id}/partners_offers`,
  getAllOffers: `/partners_offers`,
  usedCouponCodes: `/coupon_codes/used`,

  getComments: "/comments/review",
  getCommentReplies: (id = "") => `/comments/${id}`,
  changeCommentStatus: (id = "") => `/comments/${id}/review`,
  replyToComment: (id = "") => `/comments/${id}/reply`,
  updateComment: (id = "") => `/comments/${id}/edit`,
  getPolicies: "/policies",
  createPolicy: "/policies",
  getPolicy: (id = "") => `/policies/${id}`,
  deletePolicy: (id = "") => `/policies/${id}`,
  updatePolicy: (id = "") => `/policies/${id}`,

  getConfigs: "/configs",
  addConfig: "/configs",
  updateConfig: (id = "") => `/configs/${id}`,

  getPopularKeywords: "/popular_keywords",
  createPopularKeyword: "/popular_keywords",
  getPopularKeyword: (id = "") => `/popular_keywords/${id}`,
  deletePopularKeyword: (id = "") => `/popular_keywords/${id}`,
  updatePopularKeyword: (id = "") => `/popular_keywords/${id}`,

  getSections: "/sections",
  createSection: "/sections",
  getSection: (id = "") => `/sections/${id}`,
  deleteSection: (id = "") => `/sections/${id}`,
  updateSection: (id = "") => `/sections/${id}`,

  getPages: "/pages",
  createPage: "/pages",
  getPage: (id = "") => `/pages/${id}`,
  deletePage: (id = "") => `/pages/${id}`,
  updatePage: (id = "") => `/pages/${id}`,

  getEvents: "/events",
  createEvents: "/events",
  updateEvents: (id = "") => `/events/${id}`,
  getEvent: (id = "") => `/events/${id}`,
  deleteEvents: (id = "") => `/events/${id}`,
  getEventReview: (id = "") => `events/${id}/reviews`,

  getSchedulesEvents: "/event_schedules",
  createSchedulesEvents: "/event_schedules",
  updateSchedulesEvents: (id = "") => `/event_schedules/${id}`,
  getSchedulesEvent: (id = "") => `/event_schedules/${id}`,
  deleteSchedulesEvents: (id = "") => `/event_schedules/${id}`,
  cancelSchedulesEvents: (id = "") => `/event_schedules/${id}/cancel`,
  getEventAttendees: (id = "") => `/event_schedules/${id}/attendees`,
  exportEventAttendees: (id = "") => `/event_schedules/${id}/attendees/export`,
  startEvent: (id = "") => `/event_schedules/${id}/start`,
  getEventReviews: (id = "") => `/event_schedules/${id}/reviews`,
  exportSchedulesEvents: "/event_schedules/export",
  addEventNote: (id = "") => `event_schedules/${id}/add_update_note`,
  getEventNote: (id = "") => `/event_schedules/${id}/notes`,
  changeReviewStatus: (id = "") => `/reviews/${id}/change_status`,
  getPrivateUrl: (id = "") => `/event_schedules/${id}/private_url`,
  startBroadcast: (id = "") => `/event_schedules/${id}/start_broadcast`,

  getInterests: "/interests",
  createInterests: "/interests",
  updateInterests: (id = "") => `/interests/${id}`,
  getInterest: (id = "") => `/interests/${id}`,
  deleteInterests: (id = "") => `/interests/${id}`,

  //Plans
  getPlans: "/plans",
  getPlan: (id = "") => `/plans/${id}`,
  createPlans: "/plans",
  updatePlans: (id = "") => `/plans/${id}`,
  deletePlans: (id = "") => `/plans/${id}`,
  changePlanStatus: (planId = "") => `/plans/${planId}/change_status`,
  updatePlanOffers: (id = "") => `/plans/${id}/partner_offers`,

  getFaqs: "/faqs",
  getFaq: (id = "") => `/faqs/${id}`,
  createFaqs: "/faqs",
  updateFaqs: (id = "") => `/faqs/${id}`,
  deleteFaqs: (id = "") => `/faqs/${id}`,

  getNotices: "/notices",
  addNotice: "/notices",
  deleteNotice: (id = "") => `/notices/${id}`,
  updateNotcie: (id = "") => `/notices/${id}`,
  getSingleNotice: (id = "") => `notices/${id}`,
  changeNoticeStatus: (id = "") => `notices/${id}/change_status`,
  editNotice: (id = "") => `notices/${id}`,

  updateProfile: "/update_profile",
  sessions: (id = "") => `/sessions/${id}`,
  getSessionsList: (status, date) => `/sessions?status=${status}&date=${date}`,
  uploadFiles: "/s3/sign",

  //Testimonials
  getTestimonials: "/testimonials",
  addTestimonial: "/testimonials",
  deleteTestimonial: (id = "") => `/testimonials/${id}`,
  getSingleTestimonial: (id = "") => `/testimonials/${id}`,
  updateTestimonial: (id = "") => `/testimonial/${id}`,

  //hashtags
  getHashtags: `/hashtags`,

  //posts

  addPost: `/posts`,
  comments: "/comments",
  updatePost: (id) => `/posts/${id}`,
  getSocialComments: "/v2/comments",
  getUserPosts: (id) => `/users/${id}/posts`,
  getSinglePost: (id) => `/posts/${id}`,
  deleteComment: (id) => `/comments/${id}`,
  editComment: (id) => `/comments/${id}/edit`,
  viewFeed: `/feeds`,
  addVote: (poll_id, poll_choice_id) =>
    `/polls/${poll_id}/poll_choices/${poll_choice_id}/vote`,
  deletePost: (id) => `/posts/${id}`,
  updatePostStatus: (id) => `/posts/${id}/status`,
  getPosts: "/posts",
  getPostReports: (id) => `/posts/${id}/reports`,

  //reactions
  getReactions: `/reactions`,
  addReaction: `reactions`,
  deleteReaction: (id) => `/reactions/${id}`,

  //report options
  getReportsOptions: `/reports/reasons`,
  singleReportOption: (id) => `/reports/${id}`,
  addReportOption: `/reports`,

  //user stories
  getUserStories: (id) => `/users/${id}/stories`,
  getStoryViewer: (id) => `/stories/${id}/viewers`,

  //all stories
  getStories: `/all_stories`,
  updateStoryStatus: (id) => `/stories/${id}/status`,
  getStoriesReports: (id) => `/stories/${id}/reports`,

  //friends
  getUserFriends: (id) => `users/${id}/friends`,

  //user privacy settings
  getUserPrivacySettings: (id) => `/users/${id}/privacy_settings`,

  //notification
  getUserNotification: (id) => `/users/${id}/notifications`,

  //shared post data
  getSharedPostData: (id) => `/posts/${id}/shared_by`,

  //followed Instructors
  getUserFollwedInstructors: (id) => `/users/${id}/followed_instructors`,

  //post stats export
  getPostStatistics: `/posts/stats/export`,

  //reported comments and replies
  getCommentsAndReplies: `/comments/review`,

  //comment reports
  getCommentReports: (id) => `/comments/${id}/reports`,

  //Discussions
  getDiscussions: "/discussions/review",
  addDiscussion: "/discussions",
  editDiscussions: (id) => `discussions/${id}/edit`,
  changeDiscussionStatus: (id = "") => `/discussions/${id}/review`,

  //vouchers
  coupons: "/coupons",
  deleteCoupon: (id) => `/coupons/${id}`,
  getCouponCodes: (id) => `/coupons/${id}/codes`,
  downloadCodes: (id) => `/coupons/${id}/codes/export`,

  //user reports
  getReports: (id) => `/users/${id}/reports`,
  getReportedUsers: `/users/reported`,

  //block users
  getBlockedUser: (id) => `/users/${id}/blocked_users`,

  //meta-data
  metaData: "/pages_meta_data",
  singleMetaData: (id) => `/pages_meta_data/${id}`,

  //content moderation
  getContentModerations: "/content_moderation_results/stats",
  //scheduled dates
  eventScheduleDates: (id) => `/events/${id}/event_schedules/stats`,

  //offer products

  brands: "/offers/brands",
  addBrand: "/offers/brand",
  getBrandDetails: (id) => `/offers/brands/${id}`,
  updateBrandDetail: (id) => `/offers/brands/${id}`,
  products: "/offers/brands/products",
  addProduct: (id) => `/offers/brands/${id}/product`,
  getSingleProduct: (id) => `/offers/brands/products/${id}`,
  updateProduct: (id) => `/offers/brands/products/${id}`,
  offers: "/offers",
  getOffer: (id) => `/offers/${id}`,
  marketplaces: "/marketplaces",
  addMarketplace: "/marketplace",
  getSingleMarketPlace: (id) => `/marketplaces/${id}`,
};
